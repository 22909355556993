
import './App.scss';
import { ThemeProvider } from '@emotion/react';
import Routing from './Routing';
import { ColorModeContext, useMode } from './themes/Themes';
import { CssBaseline } from '@mui/material';
function App() {

  const [theme, colorMode] = useMode();


  //const [theme, setTheme] = useState('light');
  /* const toggleTheme = (t) => {
    t === 'light' ? setTheme('dark') : setTheme('light');
  } */

  return (
    <ColorModeContext.Provider value={colorMode}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Routing />

      </ThemeProvider>


    </ColorModeContext.Provider >


  );
}

export default App;
