/* import { DarkTheme } from "./DarkTheme";
import { LightTheme } from "./LightTheme";
 */

import { createTheme } from "@mui/material/styles";
import { createContext, useMemo, useState } from "react"


export const themeSettings = (mode) => {

    return {
        palette: {
            mode: mode,
        }
    }
}


export const ColorModeContext = createContext({
    toggleColorMode: () => { }
});


export const useMode = () => {
    const [mode, setMode] = useState('light');
    const colorMode = useMemo(() => ({
        toggleColorMode: () =>
            setMode((prev) => (prev === 'light' ? 'dark' : 'light')),

    }), []);

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme,colorMode];
}