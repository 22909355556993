
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DemoPage from "./pages/Landing/Demo/DemoPage";

const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route> */}

                <Route path="/" element={<DemoPage />} />
                <Route path="/demo" element={<DemoPage />} />


            </Routes>
        </BrowserRouter>
    )
}

export default Routing