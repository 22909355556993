
import { Box, Typography } from '@mui/material'
import AppBarComponent from '../../../components/AppBarComponent/AppBarComponent';

const DemoPage = () => {

    return (
        < Box>
            <AppBarComponent />
            <Typography variant='h2'>demo heading text</Typography>
        </Box>
    )
}

export default DemoPage